<template>
  <AppPage :fix-header="true">
    <TheHeroVideoSection />
    <TheVideoSection/>
    <TheTestimonialSection />
    <TheMetadataSection />
    <TheUseCaseSection/>
    <section style="background: #000; text-align: center; padding: 3em 2em; background-image:url('/api/storyboards/646feaea6276ac594213adf5/Shot-3.png'); background-size: cover; background-position: center center; ">
      <div class="app-width">
        <h2>Dollar Shave Club</h2>
        <h1>Brand Analysis</h1>
        <p style="max-width: 800px; margin:1em auto; color: white;">
          Hold onto your monocles, ladies and gents, because we're about dive into the heart of the pop culture juggernaut, that is the Dollar Shave Club ad from 2012. This isn't just an absurdly funny ad; it fundamentally changed the way many marketers engage with online advertising today.
        </p>
        <Button to="/b/646feaea6276ac594213adf5" icon="view-grid" :has-chevron="true" class="primary">
          View the Storyboard
        </Button>
      </div>
    </section>
    <section style="background: #000; text-align: center; padding: 3em 2em; background-image:url('/api/storyboards/647269155473255d1351bfa7/Shot-13.png'); background-size: cover; background-position: center center; ">
      <div class="app-width">
        <h2>Game of Thrones</h2>
        <h1>Cinematic Analysis</h1>
        <p style="max-width: 800px; margin:1em auto;">
          In this scene breakdown we use StoryFolder to dissect and understand the complex narrative devices and cinematic techniques used to create one of the most dramatic and memorable scenes from Game of Thrones: the Sept of Baelor explosion scene.
        </p>
        <Button to="/b/647269155473255d1351bfa7" icon="view-grid" :has-chevron="true" class="primary">
          View the Storyboard
        </Button>
      </div>
    </section>
    <TheIntroSection />
    <TheExportSection />
    <TheFeaturetteSection />
    <TheThirdPartyToolSection/>
    <TheExperienceSection />
    <TheSystemRequirementsSection />
  </AppPage>
</template>

<script lang="ts">
import AppPage from "@/components/organisms/AppPage.vue";
import TheHeroVideoSection from "@/components/organisms/TheHeroVideoSection.vue";
import TheIntroSection from "@/components/organisms/TheIntroSection.vue";
import TheFeaturetteSection from "@/components/organisms/TheFeaturetteSection.vue";
import TheExperienceSection from "@/components/organisms/TheExperienceSection.vue";
import TheSystemRequirementsSection from "@/components/organisms/TheSystemRequirementsSection.vue";
import TheTestimonialSection from "@/components/organisms/TheTestimonialSection.vue";
import TheThirdPartyToolSection from "@/components/organisms/TheThirdPartyToolSection.vue";
import TheMetadataSection from "@/components/organisms/TheMetadataSection.vue";
import TheExportSection from "@/components/organisms/TheExportSection.vue";
import TheUseCaseSection from "@/components/organisms/TheUseCaseSection.vue";
import TheVideoSection from "@/components/organisms/TheVideoSection.vue";
import Button from "@/components/atoms/Button.vue";


export default {
  components: {
    Button,
    TheVideoSection,
    TheExportSection,
    TheMetadataSection,
    AppPage,
    TheHeroVideoSection,
    TheIntroSection,
    TheFeaturetteSection,
    TheExperienceSection,
    TheSystemRequirementsSection,
    TheTestimonialSection,
    TheThirdPartyToolSection,
    TheUseCaseSection
  },

  watch: {
    '$store.state.user.user.email':{
      immediate: true,
      handler(){

      }
    }
  }
}
</script>

<style scoped lang="less">
  @import '../less/variables';


  video{
    width: 100%;
    outline: 0;
    text-align: center;
  }

</style>
